<template>
  <div :style="{ height: h + 'px' }">
    <div class="login-bg"></div>
    <div class="login">
      <el-header class="login-header" height="90px">
        <div class="login-header-title">
          <el-image
            class="login-header-logo"
            :src="require('../../assets/image/logo_bg.png')"
            fit="cover"
          ></el-image>
          <div>Uummii在线教育数据管理平台</div>
        </div>
      </el-header>
      <div class="login-content">
        <div class="login-module">
          <div class="login-group">
            <div class="login-title">童言在线</div>
            <el-form :model="ruleForm" class="login-form">
              <el-form-item prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
              <el-form-item prop="password" class="login-form-item">
                <el-input
                  v-model="ruleForm.password"
                  type="password"
                  @keyup.enter="submitForm"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <div class="login-operate">
                  <el-checkbox v-model="checked" @change="handSavePsd"
                    >记住密码</el-checkbox
                  >
                  <router-link :to="{ name: 'forgotPassword' }"
                    >忘记密码</router-link
                  >
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="login-submit"
                  :loading="loading"
                  @click="submitForm"
                  >{{ loginTitle }}</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <el-image
            class="login-img"
            :src="require('../../assets/image/loginpic.png')"
            fit="cover"
          ></el-image>
        </div>
        <div class="login-bottom">
          <div>童演童语（北京）教育科技有限公司 | 京ICP备1300570</div>
          <div>技术支持：北京启航视讯科技有限公司</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Login } from "@/api/apiList/admin-api";

export default {
  data() {
    return {
      h: 0,
      ruleForm: {
        name: "",
        password: "",
      },
      checked: false,
      loading: false,
      loginTitle: "登录",
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handSavePsd(e) {
      this.checked = e;
    },
    submitForm() {
      if (this.ruleForm.name === "") {
        this.$message.error("请输入账号");
      } else if (this.ruleForm.password === "") {
        this.$message.error("请输入密码");
      } else {
        this.loading = true;
        this.loginTitle = "登录中";
        Login(this.ruleForm).then((res) => {
          if (res.result === "200") {
            if (this.checked) {
              this.$cookies.set("userpassword", this.ruleForm, "15d");
            }
            this.loading = false;
            this.loginTitle = "登录";
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("username", res.data.username);
            localStorage.setItem(
              "menuList",
              JSON.stringify(res.data.permissions)
            );
            this.$store.commit("changeMenuIndex", "");
            this.$store.commit("changeTagArr", []);
            this.$router.push("/");
          } else if (res.result === "200002") {
            this.$message.error(res.msg);
            this.loading = false;
            this.loginTitle = "登录";
          }
        });
      }
    },
  },
  mounted() {
    this.h = document.documentElement.clientHeight;
    if (this.$cookies.get("userpassword")) {
      this.ruleForm = this.$cookies.get("userpassword");
      this.checked = true;
    } else {
      this.ruleForm = {
        name: "",
        password: "",
      };
      this.checked = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.login-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/image/bg_max.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: blur(20px);
  z-index: -1;
}

.login {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);

  .login-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 40px;
    background: #fff;

    .login-header-title {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: 600;

      .login-header-logo {
        width: 70px;
        height: 70px;
        margin-right: 20px;
      }
    }
  }

  .login-content {
    width: 60%;
    min-width: 800px;
    margin: 0 auto;
    margin-top: 50px;

    .login-module {
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 10px;

      .login-group {
        flex: 1;

        .login-title {
          font-size: 32px;
          font-weight: 500;
          color: #409eff;
          text-align: center;
        }

        .login-form {
          width: 320px;
          margin: 40px auto;

          .login-form-item {
            margin-bottom: 5px;
          }

          .login-operate {
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
              text-decoration: none;
              color: #409eff;
            }
          }

          .login-submit {
            width: 100%;
          }
        }
      }

      .login-img {
        width: 40%;
        min-width: 320px;
      }
    }

    .login-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      font-size: 12px;
      color: #333;
    }
  }
}
</style>
