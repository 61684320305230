import { post } from "../request";

// 管理员登录
export const Login = (params) => post("/login", params);

// 修改密码
export const resetPassword = (params) => post("/resetPassword", params);

// 重置密码
export const forgotPassword = (params) => post("/forgotPassword", params);

// 获取验证码
export const sendMsg = (params) => post("/sendMsg", params);
